import React from 'react';
import { Edges } from '../../types/index';
import FeedItem from './FeedItem';

type Props = {
   edges: Edges;
};

const Feed = ({ edges }: Props) => (
   <div>
      {edges.map((edge) => (
         <FeedItem key={edge.node.fields.slug} edge={edge} />
      ))}
   </div>
);

export default Feed;
